import Layout from "../layouts/Layout/Layout"
import HeroSubpage from "../sections/HeroSubpage/HeroSubpage"
import Background from "../assets/images/hero_parent.jpg"
import React from "react"
import Faq from "../sections/Faq/Faq"

const FaqPage = () => (
  <Layout>
    <HeroSubpage title="FAQ" backgroundImage={Background} />
    <Faq
      noSeparator={true}
      lastChild
      faqItems={[
        {
          question: "Czym jest Program Alkantara?",
          answer:
            "<p>Program Alkantara to zajęcia biznesowe oraz fundusz stypendialny stworzony dla zdolnych studentów z województwa pomorskiego i nie tylko. Obejmuje 4 obszary: edukację, mentoring, praktykę zawodową Jego celem jest rozwój kompetencji biznesowych młodych ludzi i zapewnienie im lepszego startu na rynku pracy.</p>",
        },
        {
          question: "Kto może wziąć udział w Akademii Alkantara?",
          answer:
            "<p>Do Programu Alkantara mogą zgłaszać się zdolni uczniowie klas maturalnych, którzy chcą studiować na trójmiejskich uczelniach. Żeby dowiedzieć się więcej, skontaktuj się ze swoim wychowawcą lub doradcą zawodowym w swojej szkole. To osoby, które pomogą Ci wypełnić wszystkie dokumenty potrzebne w procesie rekrutacji. Nabór na rok akademicki 2021/2022 trwa do 12 grudnia 2020.</p>",
        },
        {
          question: "Kto może skorzystać z Funduszu Stypendialnego?",
          answer:
            "<p>Udział w Funduszu Stypendialnym mogą zgłaszać osoby, które z różnych przyczyn nie mogą podjąć wymarzonych studiów w Trójmieście. Każde zgłoszenie rozpatrujemy indywidualnie. Wysokość stypendium ustalamy tak, by umożliwić naszym uczestnikom utrzymanie w mieście, w którym chcą studiować. Bierzemy pod uwagę indywidualne potrzeby uczestnika, uwzględniając koszty utrzymania oraz zapewnienie niezbędnych warunków do nauki i rozwoju.</p>",
        },
        {
          question:
            "Czym się różni Akademia Alkantara od Funduszu Stypendialnego?",
          answer:
            "<p>Akademia Alkantara to program angażujących zajęć i warsztatów biznesowych prowadzonych przez doświadczonych praktyków (przedsiębiorców, managerów i szkoleniowców związanych z biznesem). Program Akademii Alkantara realizujemy w trzech obszarach: edukacji, mentoringu i praktyce zawodowej.</p>" +
            "<p>Z kolei Fundusz Stypendialny to druga część Programu Alkantara. Jego celem jest wyrównywanie szans młodych i zdolnych ludzi przez pomoc w finansowaniu potrzeb związanych ze studiami w Gdańsku, Gdyni czy w Sopocie. Wysokość stypendium ustalamy tak, by umożliwić naszym uczestnikom utrzymanie w mieście, w którym chcą studiować. Zawsze bierzemy pod uwagę indywidualne potrzeby uczestnika, uwzględniając koszty utrzymania oraz zapewnienie niezbędnych warunków do nauki i rozwoju.</p>" +
            "<p><strong>Można być wyłącznie uczestnikiem Akademii Alkantara lub korzystać zarówno z możliwości Akademii, jak i Funduszu Stypendialnego.</strong></p>",
        },
        {
          question: "Co zyskuję dzięki udziałowi w Programie Alkantara?",
          answer:
            "<p>Dzięki udziałowi w Programie Alkantara masz dostęp do cennej wiedzy zawodowej i otrzymujesz kompleksowe wsparcie osób, które osiągnęły sukces w biznesie. Jako uczestnik Programu Alkantara:</p>" +
            "<ul>" +
            "<li>zdobywasz cenne kompetencje biznesowe</li>" +
            "<li>korzystasz z indywidualnej opieki mentora</li>" +
            "<li>bierzesz udział w profesjonalnych warsztatach</li>" +
            "<li>poznajesz szczegółowe zasady funkcjonowania firm</li>" +
            "<li>współpracujesz z ekspertami ze świata biznesu</li>" +
            "<li>podejmujesz płatną praktykę zawodową</li>" +
            "<li>możesz znaleźć pracę w firmach partnerskich</li>" +
            "<li>możesz otrzymać dopasowane do potrzeb stypendium</li>" +
            "</ul>",
        },
        {
          question:
            "Jakie warunki muszę spełnić, żeby wziąć udział w programie Alkantara?",
          answer:
            "<p>Do udziału w Programie Alkantara zapraszamy wszystkich zdolnych maturzystów, którzy chcą studiować na Trójmiejskich uczelniach. Jeśli wyróżniasz się świetnymi wynikami w nauce i chcesz od pierwszego roku studiów budować swoje zawodowe kompetencje, zgłoś się do Programu Alkantara. By to zrobić, skontaktuj się ze swoim wychowawcą lub doradcą zawodowym w swojej szkole. To osoby, które pomogą Ci wypełnić wszystkie dokumenty potrzebne w procesie rekrutacji. Nabór na rok akademicki 2021/2022 trwa do 12 grudnia 2020.</p>",
        },
        {
          question:
            "Czy zajęcia w Akademii Alkantara mogą kolidować z zajęciami na uczelni?",
          answer:
            "<p>Akademia Alkantara jest programem komplementarnym do edukacji na uczelniach wyższych czyli z zasady ma uzupełniać zajęcia akademickie. Zajęcia i spotkania z mentorami ustalamy zawsze w porozumieniu z naszymi uczestnikami, by wszyscy mogli swobodnie brać w nich udział.</p>",
        },
        {
          question:
            "Co się stanie, jeśli nie pogodzę studiów z zajęciami w ramach Akademii Alkantara?",
          answer:
            "<p>Udział w Programie Alkantara jest dobrowolny i nie pociąga żadnych zobowiązań wobec organizatorów. Jeśli zrezygnujesz z zajęć w Akademii Alkantara, nie ponosisz żadnej kary. Jeśli korzystasz z Funduszu Stypendialnego, nie musisz oddawać pobranych wcześniej pieniędzy. Nie jesteś też zobowiązana/ zobowiązany do podjęcia praktyk zawodowych w partnerskich firmach Programu Alkantara.</p>",
        },
        {
          question:
            "Jakie są moje zobowiązania w związku z udziałem w Programie Alkantara?",
          answer:
            "<p>Udział w Programie Alkantara jest dobrowolny i nie pociąga żadnych zobowiązań wobec organizatorów. Zakładamy, że każdy uczestnik dołącza do naszej inicjatywy, żeby korzystać ze wsparcia mentorów, rozwijać się zawodowo i realnie zwiększyć swoje szanse na rynku pracy.</p>",
        },
        {
          question:
            "Co się stanie, gdy zrezygnuję z udziału w Programie Alkantara?",
          answer:
            "<p>Nie czekają Cię żadne konsekwencje. Rezygnacja z Programu Alkantara, tak jak deklaracja udziału, jest w pełni dobrowolna.</p>",
        },
        {
          question: "Kim jest mentor i na czym polega nasza współpraca?",
          answer:
            "<p>Mentor to specjalnie wybrana osoba o ugruntowanej pozycji w biznesie (np. doświadczony przedsiębiorca, manager kierujący zespołem czy szkoleniowiec). Jego misją jest dzielenie się z Tobą swoim doświadczeniem i umiejętnościami. Każdy z uczestników Programu Alkantara ma swojego mentora, od którego uzyskuje wsparcie i przydatne rady w obszarze edukacji i rozwoju zawodowego. Dzięki współpracy z mentorem możesz w prosty sposób zobaczyć, jak wygląda praca specjalistów i funkcjonowanie firm w praktyce.</p>",
        },
        {
          question:
            "O co mogę pytać mentora i w jakich sprawach mogę sięgać po radę mentora?",
          answer:
            "<p>Rolą mentora jest wsparcie podopiecznego w czterech najważniejszych obszarach: prowadzenia działalności biznesowej, skutecznego zarządzania projektami i zespołami, wykorzystania umiejętności biznesowych w praktyce, a także budowania dobrych relacji zawodowych. Jeśli potrzebujesz porady lub odpowiedzi na ważne pytanie związane z Twoją edukacją lub rozwojem zawodowym – mentor z pewnością będzie mógł Ci pomóc.</p>",
        },
        {
          question:
            "Ile czasu muszę poświęcić tygodniowo na zajęcia w Akademii Alkantara?",
          answer:
            "<p>Ilość zajęć w pierwszych latach trwania studiów jest mniejsza, a godziny ustalamy zawsze indywidualnie z uczestnikami Programu Alkantara w taki sposób, by nie kolidowały z zajęciami na uczelniach wyższych. Z biegiem czasu ilość zajęć rośnie (proporcjonalnie do mniejszej ilości zajęć akademickich), a ich czas – tak jak wcześniej – ustalamy w porozumieniu z uczestnikami.</p>",
        },
        {
          question:
            "Co jeśli zajęcia w Akademii Alkantara będą pokrywać się z zajęciami na uczelni?",
          answer:
            "<p>Nie ma takiej możliwości. Szczegóły dotyczące zajęć w ramach Akademii Alkantara zawsze ustalamy w porozumieniu z naszymi uczestnikami. Zależy nam, by wszyscy mogli swobodnie i bez obaw korzystać z możliwości, jakie oferuje inicjatywa. </p>",
        },
        {
          question:
            "Czy po zakończeniu Akademii Alkantara otrzymuję dyplom lub inny dokument potwierdzający mój udział?",
          answer:
            "<p>Po zakończeniu (czyli po 5 latach) otrzymujesz dyplom ukończenia Akademii Alkantara, który potwierdza zdobytą wiedzę, cenne kontakty i umiejętności biznesowe. To dokument, który możesz wykorzystać w procesach rekrutacyjnych jako istotną przewagę konkurencyjną nad innymi kandydatami. Z dyplomem Akademii Alkantara zdecydowanie łatwiej zdobędziesz wymarzoną pracę i szybko rozwiniesz karierę. </p>",
        },
        {
          question:
            "Czy każdy uczestnik może liczyć na płatne praktyki zawodowe?",
          answer:
            "<p>Każdemu uczestnikowi Programu Alkantara składamy ofertę podjęcia płatnych praktyk zawodowych, które odbywają się w partnerskich firmach Programu Alkantara. Udział w takich praktykach jest dobrowolny i nie pociąga za sobą żadnych późniejszych zobowiązań. Może być za to doskonałym początkiem zawodowej przygody.</p>",
        },
      ]}
    />
  </Layout>
)

export default FaqPage
